import * as React from 'react';
import { useSelector } from 'react-redux';

import { MessageButton } from '../../components/MessageButton';
import { useBuilderChat } from '../../hooks/builderChat';
import { getUser } from '../../selectors/user';
import { trackSendMessageClick } from './tracking';
import { IBuilder } from '../../types/newbuilding';
import { useBuilderChatParams } from './hooks';
import { TButtonSize } from '@cian/ui-kit/button/types';

interface IBuilderChatButtonContainerProps {
  gaLabel: string;
  builders: IBuilder[];
  buttonSize?: TButtonSize;
  isPromoSnippet?: boolean;
}

export const BuilderChatButtonContainer: React.FC<IBuilderChatButtonContainerProps> = ({
  gaLabel,
  builders,
  buttonSize = 'XS',
  isPromoSnippet = false,
}) => {
  const user = useSelector(getUser);
  const { builderRealtyUserId, defaultMessage, isChatEnabled } = useBuilderChatParams(builders);
  const { openChat } = useBuilderChat({ defaultMessage, gaLabel, builderRealtyUserId });

  const handleClick = React.useCallback(() => {
    trackSendMessageClick({ label: gaLabel, isPromoSnippet });
    openChat();
  }, [gaLabel, isPromoSnippet, openChat]);

  const isOwnNewbuilding = builderRealtyUserId === (user.isAuthenticated && user.userId);

  if (!isChatEnabled || isOwnNewbuilding) {
    return null;
  }

  return (
    <MessageButton buttonSize={buttonSize} onClick={handleClick}>
      Написать застройщику
    </MessageButton>
  );
};

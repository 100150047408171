import { ILogger } from '@cian/logger';
import { IHttpApi } from '@cian/http-api';

import {
  IGetUserDataByRequestResponse200,
  IGetUserDataByRequestResponse400,
  fetchGetUserDataByRequest,
} from '../../repositories/monolith-python/v1/get-user-data-by-request';

export async function getUserData(cookie: string, logger: ILogger, httpApi: IHttpApi, subdomain: string) {
  try {
    const { response } = await fetchGetUserDataByRequest<
      IGetUserDataByRequestResponse200,
      IGetUserDataByRequestResponse400
    >({ httpApi, parameters: {}, config: { subdomain } });

    return response;
  } catch (error) {
    if (logger) {
      logger.error(error, {
        message: error.message,
        stack: error.stack,
        comment: 'Error in getUserData',
      });
    }
    throw error;
  }
}

import { getValueFromComputedStyle } from './getValueFromComputedStyle';

/** кнопка очистить и развернуть **/
const BUTTONS_COUNT = 2;

interface IGetLastVisibleItemIndexParams {
  itemsWidths: number[];
  maxWidth: number;
}

const getLastVisibleItemIndex = ({ itemsWidths, maxWidth }: IGetLastVisibleItemIndexParams) => {
  let lastIndex = 0;

  itemsWidths.reduce((acc, item, index) => {
    if (lastIndex) {
      return acc;
    }

    const nextAcc = acc + item;

    if (nextAcc > maxWidth) {
      lastIndex = index - BUTTONS_COUNT;

      return acc;
    }

    return nextAcc;
  }, 0);

  return lastIndex;
};

interface IRecalcParams {
  wrapperRef: React.RefObject<HTMLDivElement>;
  maxWidth: number;
}

export const calcLastIndex = ({ wrapperRef, maxWidth }: IRecalcParams) => {
  if (!wrapperRef.current) {
    return;
  }

  const items = wrapperRef.current.childNodes;

  const itemsWidths = [];
  let i = 0;
  const lastIndex = Math.min(items.length, 15);
  for (i; i < lastIndex; i++) {
    const element = items[i] as HTMLDivElement;
    if (element && element.tagName.toLowerCase() !== 'button' && !element.classList.contains('button')) {
      const computedStyle = window.getComputedStyle(element);
      itemsWidths.push(element.getBoundingClientRect().width + getValueFromComputedStyle(computedStyle, 'marginRight'));
    }
  }

  return getLastVisibleItemIndex({ itemsWidths, maxWidth });
};

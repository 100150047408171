import * as React from 'react';
import { Container } from '../../components/Container';
import { SIZE_16, SIZE_4 } from '../../constants/sizes';
import { SeoSubtitles } from '../../components/SeoSubtitles/SeoSubtitles';
import { ArticleParagraph3 } from '@cian/ui-kit';
import { useSelector } from 'react-redux';
import { getIsFilterUpcomingSaleEnabled } from '../../selectors/location';
import { getMarkedListItems, getSubtitle } from '../../selectors/seo';
import { getMapFullUrl } from '../../selectors/offersData';

export const Subheading = () => {
  const isInfoSection = useSelector(getIsFilterUpcomingSaleEnabled);
  const markedList = useSelector(getMarkedListItems);
  const mapFullUrl = useSelector(getMapFullUrl);
  const subtitle = useSelector(getSubtitle);

  const subtitles = React.useMemo(() => {
    return markedList.find(item => item.underH1)?.content;
  }, [markedList]);

  const showSubtitles = React.useMemo(() => {
    return Boolean(subtitles || mapFullUrl || subtitle);
  }, [subtitles, mapFullUrl, subtitle]);

  return (
    <>
      {showSubtitles && <SeoSubtitles subtitles={subtitles} mapFullUrl={mapFullUrl as string} subtitle={subtitle} />}
      {isInfoSection && (
        <Container margin={`${SIZE_4} 0 0 ${SIZE_16}`}>
          <ArticleParagraph3 color="gray40_100">Информационный раздел</ArticleParagraph3>
        </Container>
      )}
    </>
  );
};

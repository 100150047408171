import * as React from 'react';
import { useSelector } from 'react-redux';
import { TButtonSize } from '@cian/ui-kit/button/types';

import { selectConsultantMessageButtonAvailable } from '../../selectors/consultantChat';
import { BuilderChatButtonContainer } from '../BuilderChatButton';
import { INewbuilding } from '../../types/newbuilding';
import { isBuilderChatEnabledByNewbuilding } from '../../utils/builderChat';
import { ConsultantChatButtonContainer } from '../ConsultantChatButtonContainer';

interface IMessageButtonContainerProps {
  newbuilding: INewbuilding;
  buttonSize?: TButtonSize;
  isPromoSnippet?: boolean;
}

export const MessageButtonContainer: React.FC<IMessageButtonContainerProps> = ({
  newbuilding,
  buttonSize,
  isPromoSnippet,
}) => {
  const isConsultantMessageButtonAvailable = useSelector(selectConsultantMessageButtonAvailable);
  const isBuilderChatEnabled = isBuilderChatEnabledByNewbuilding(newbuilding);

  // TODO: Удалить в CD-225149, если эксперимент неудачен.
  if (isConsultantMessageButtonAvailable && !isBuilderChatEnabled) {
    return <ConsultantChatButtonContainer buttonSize={buttonSize} />;
  }

  return (
    <BuilderChatButtonContainer
      gaLabel={newbuilding.gaLabels.full}
      builders={newbuilding.builders}
      buttonSize={buttonSize}
      isPromoSnippet={isPromoSnippet}
    />
  );
};

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeBuilderChat } from '../../actions/builderChat';
import { BuilderChatPopup } from '../../components/BuilderChatPopup';
import { useBuilderChatUrl } from '../../hooks/builderChat';
import { getBuilderChatOpened } from '../../selectors/builderChat/getBuilderChatOpened';

export const BuilderChatPopupContainer: React.FC = () => {
  const dispatch = useDispatch();

  const isChatOpened = useSelector(getBuilderChatOpened);
  const chatUrl = useBuilderChatUrl({ isChatOpened });

  const handleClose = React.useCallback(() => dispatch(closeBuilderChat()), [dispatch]);

  if (!isChatOpened) {
    return null;
  }

  return <BuilderChatPopup url={chatUrl} onClose={handleClose} />;
};

import * as React from 'react';
import { LinkButton, UIText2 } from '@cian/ui-kit';
import * as styles from './SeoSubtitles.css';

import { IconActionMap16 } from '@cian/ui-kit-design-tokens/icons';

interface ISeoSubtitlesProps {
  subtitles?: string;
  subtitle?: string;
  mapFullUrl?: string;
}
export const SeoSubtitles: React.FC<ISeoSubtitlesProps> = ({ subtitles, mapFullUrl, subtitle }) => {
  return (
    <div className={styles['subtitles']}>
      {/* eslint-disable-next-line react/no-danger */}
      {subtitles && <div className={styles['list']} dangerouslySetInnerHTML={{ __html: subtitles }}></div>}
      {!subtitles && subtitle && <UIText2>{subtitle}</UIText2>}
      {mapFullUrl && (
        <div className={styles['map-button']}>
          <LinkButton
            href={mapFullUrl}
            target="_blank"
            size="XS"
            theme={'fill_white_primary'}
            beforeIcon={<IconActionMap16 color="icon-main-default" />}
            data-testid="MapLink"
          >
            На карте
          </LinkButton>
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import { TButtonSize } from '@cian/ui-kit/button/types';
import { useDispatch, useSelector } from 'react-redux';

import { toggleChat, toggleJumpAnimation } from '../../actions/consultantChat';
import { MessageButton } from '../../components/MessageButton';
import { chatOpenSelector } from '../../selectors/consultantChat';

interface IConsultantChatButtonContainerProps {
  buttonSize?: TButtonSize;
}

export const ConsultantChatButtonContainer: React.FC<IConsultantChatButtonContainerProps> = ({ buttonSize = 'XS' }) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(chatOpenSelector);

  const handleOpen = React.useCallback(() => {
    if (isOpen) {
      dispatch(toggleJumpAnimation(true));
    } else {
      dispatch(toggleChat(true));
    }
  }, [dispatch, isOpen]);

  return (
    <MessageButton buttonSize={buttonSize} onClick={handleOpen}>
      Написать
    </MessageButton>
  );
};

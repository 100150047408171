import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from '../../components/Container';
import { definitions } from '../../actions/filterTags/definitions';
import { SIZE_8 } from '../../constants/sizes';
import { rendererFactory } from './defaultRenderer';
import { TTypedThunkDispatch } from '../../types/thunk';
import { updateTags } from '../../actions/filterTags';
import { getOffersCount } from '../../actions/offersData';
import { TagsWrapper } from '../../components/TagsWrapper';
import { getTags } from '../../selectors/filters';

interface ITagContainerProps {
  children: React.ReactNode;
}

function TagContainer(props: ITagContainerProps) {
  return (
    <Container display="inline-block" margin={`0 ${SIZE_8} ${SIZE_8} 0`}>
      {props.children}
    </Container>
  );
}

export const FilterTags = () => {
  const dispatch = useDispatch<TTypedThunkDispatch>();
  const tags = useSelector(getTags);

  const [maxWidth, setMaxWidth] = React.useState<number>(0);
  const ref = React.useRef<HTMLDivElement>(null);

  const clearAllTags = React.useCallback(() => {
    tags.forEach(tag => {
      const definition = definitions.get(tag.keyName);
      if (definition) {
        const onDeleteAction = definition.onDeleteAction(tag.value, { skipUpdateOffersCount: true });
        if (onDeleteAction) {
          dispatch(onDeleteAction);
        }
      }
    });
    dispatch(getOffersCount());
    dispatch(updateTags());
  }, [dispatch, tags]);

  const updateTagsMaxWidth = React.useCallback(() => {
    if (!ref.current) {
      return;
    }

    const tagsMaxWidth = ref.current.getBoundingClientRect().width;

    setMaxWidth(tagsMaxWidth);
  }, [ref]);

  React.useLayoutEffect(() => {
    updateTagsMaxWidth();
    window.addEventListener('resize', updateTagsMaxWidth);

    return () => {
      window.removeEventListener('resize', updateTagsMaxWidth);
    };
    // смотрим и на изменение количества тего, чтобы обновить ширину
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTagsMaxWidth, tags]);

  if (!tags.length) {
    return null;
  }

  return (
    <div ref={ref}>
      <TagsWrapper maxWidth={maxWidth} clearAllTags={clearAllTags}>
        {tags.map((tag, index) => {
          const definition = definitions.get(tag.keyName);

          if (!definition) {
            return null;
          }

          const Component = rendererFactory(definition);

          return (
            <TagContainer key={index}>
              <Component {...tag} />
            </TagContainer>
          );
        })}
      </TagsWrapper>
    </div>
  );
};

import { NEWBUILDING_CONSULTANT_MESSAGE_BUTTON } from '../../constants/experiments';
import { IApplicationState } from '../../types/redux';
import { isAbUseExperimentGroup } from '../../utils/experiments';

export function accountNameSelector(state: IApplicationState) {
  return state.consultantChat.accountName;
}

export function chatHostSelector(state: IApplicationState) {
  return state.consultantChat.host;
}

export function chatOpenSelector(state: IApplicationState) {
  return state.consultantChat.chatOpen;
}

export function needForceOpenSelector(state: IApplicationState) {
  return state.consultantChat.chatNeedForceOpen;
}

export function selectNeedRunJumpAnimation(state: IApplicationState) {
  return state.consultantChat.chatNeedRunJumpAnimation;
}

// TODO: Удалить эксперимент в CD-225149.
export const selectConsultantMessageButtonAvailable = (state: IApplicationState): boolean => {
  const { config, abUseExperiments, consultantChat } = state;

  return (
    config.isConsultantMessageButtonEnabled &&
    Boolean(consultantChat.accountName) &&
    isAbUseExperimentGroup(abUseExperiments, NEWBUILDING_CONSULTANT_MESSAGE_BUTTON, 'B')
  );
};

import { init as initConfig } from '@cian/config/browser';
import { init as initLogger } from '@cian/logger/browser';
import { initGlobalEventsLogs } from '@cian/events-log';
import { init as initTelemetry } from '@cian/telemetry/browser';
import { init as initHttpApi } from '@cian/http-api/browser';
import { init as initWeb } from '@cian/web/browser';
import { init as initGlobalBehaviors } from '@cian/global-behaviors/browser';

export function initDependencies() {
  initConfig();
  initLogger();
  initGlobalEventsLogs();
  initTelemetry();
  initHttpApi();
  initWeb();
  initGlobalBehaviors();
}

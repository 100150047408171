export enum ENBConsutantActions {
  ToggleChat = 'consultantChat/toggleChat',
  ToggleJumpAnimation = 'consultantChat/toggleJumpAnimation',
}

export interface IConsultantChatAction {
  type: ENBConsutantActions;
  payload: boolean;
}

export function toggleChat(open: boolean): IConsultantChatAction {
  return {
    type: ENBConsutantActions.ToggleChat,
    payload: open,
  };
}

export function toggleJumpAnimation(run: boolean): IConsultantChatAction {
  return {
    type: ENBConsutantActions.ToggleJumpAnimation,
    payload: run,
  };
}

export type TConsultantChatActions = IConsultantChatAction;

import { ca } from '@cian/analytics';

interface ITrackSendMessageClickParams {
  label: string;
  isPromoSnippet?: boolean;
}

export function trackSendMessageClick({ label, isPromoSnippet }: ITrackSendMessageClickParams) {
  ca('eventSite', {
    action: 'send_message',
    category: 'ListingJK',
    label,
    name: 'oldevent',
    useLastProducts: true,
    page: {
      extra: {
        type: isPromoSnippet ? 'Open_listingjk_promo_block' : 'Open_listing_jk',
      },
    },
  });
}

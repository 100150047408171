import * as React from 'react';

import { ISuggestionGroup } from '../../../../types/suggestions';
import { SuggestItem } from '../SuggestItem';
import { getDescription } from './helpers';
import { getUndergroundColors } from './helpers/getUndergroundColors';

import * as styles from './SuggestCategory.css';

export interface ISuggestCategoryProps {
  icon: React.ReactElement;
  highlight: string;
  label: string;
  onHover(s: ISuggestionGroup): void;
  onSelect(s: ISuggestionGroup): void;
  selected?: string;
  suggests: ISuggestionGroup[];
  categoryType: string;
}

export const SuggestCategory: React.FC<ISuggestCategoryProps> = ({
  onSelect,
  selected,
  suggests,
  onHover,
  label,
  highlight,
  icon,
  categoryType,
}) => {
  return (
    <div data-testid="SuggestCategory" data-group={categoryType}>
      <div className={styles['label']}>
        <span className={styles['icon']}>{icon}</span>
        {label}
      </div>
      {suggests.map((suggest, idx) => {
        return (
          <SuggestItem
            key={suggest.value + idx}
            description={getDescription(suggest)}
            onClick={() => onSelect(suggest)}
            onHover={() => onHover(suggest)}
            selected={selected === suggest.value}
            term={highlight}
            title={suggest.title}
            undergroundColors={getUndergroundColors(suggest)}
            url={suggest.url}
          />
        );
      })}
    </div>
  );
};

import * as React from 'react';
import * as styles from './style.css';
import { clsx } from 'clsx';
import { Button } from '@cian/ui-kit';
import { calcLastIndex } from './utils/widthCalculator';

export interface ITagsWrapperProps {
  maxWidth: number;
  clearAllTags(): void;
}

export const TagsWrapper = ({ children, maxWidth, clearAllTags }: React.PropsWithChildren<ITagsWrapperProps>) => {
  const childrensArray = React.useMemo(() => React.Children.toArray(children), [children]);

  const [lastVisibleItemIndex, setLastVisibleItemIndex] = React.useState<number>();
  const [enabled, toggle] = React.useState(true);
  const [shouldRecalc, setShouldRecalc] = React.useState(true);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const handleShow = React.useCallback(() => {
    toggle(false);
  }, [toggle]);

  const handleHide = React.useCallback(() => {
    toggle(true);
  }, [toggle]);

  const recalc = React.useCallback(() => {
    if (!shouldRecalc) {
      return;
    }

    const lastIndex = calcLastIndex({
      wrapperRef,
      maxWidth,
    });

    setShouldRecalc(false);
    setLastVisibleItemIndex(lastIndex);
  }, [maxWidth, shouldRecalc]);

  const isButtonVisible = React.useMemo(() => Boolean(lastVisibleItemIndex), [lastVisibleItemIndex]);

  React.useLayoutEffect(() => {
    recalc();
  }, [children, recalc, maxWidth]);

  React.useEffect(() => {
    setShouldRecalc(true);
  }, [maxWidth, children]);

  if (!enabled) {
    return (
      <div className={styles['wrapper--inline']} ref={wrapperRef}>
        {children}
        {isButtonVisible && (
          <div className={styles['button']}>
            <Button theme="fill_secondary" size="XS" onClick={handleHide}>
              Свернуть
            </Button>
          </div>
        )}
        {childrensArray.length >= 2 && (
          <div className={styles['button']}>
            <Button onClick={clearAllTags} size="XS" theme="fill_secondary" type="button">
              Очистить
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles['wrapper']} ref={wrapperRef} data-mark="FilterTags">
      {childrensArray.map((item, index) => {
        if (shouldRecalc) {
          return item;
        }

        if (lastVisibleItemIndex && index >= Number(lastVisibleItemIndex)) {
          return null;
        }

        return item;
      })}
      {isButtonVisible && (
        <div className={clsx(styles['button'], styles['button--with-dots'])}>
          <Button theme="fill_secondary" size="XS" onClick={handleShow} data-testid="ShowMoreTagsButton">
            •••
          </Button>
        </div>
      )}
      {childrensArray.length >= 2 && (
        <div className={styles['button']}>
          <Button onClick={clearAllTags} size="XS" theme="fill_secondary" type="button">
            Очистить
          </Button>
        </div>
      )}
    </div>
  );
};

import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';

import { getHighlighted, getIndicesOf } from '../../../../utils/text';
import { Highlighter } from '../Highlighter';

export interface ISuggestTermInfoProps {
  description?: string;
  term: string;
  title: string;
}

export const SuggestTermInfo: React.FC<ISuggestTermInfoProps> = ({ description, term, title }) => {
  const highlighted = React.useMemo(() => {
    const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape для спецсимволов
    const indices = getIndicesOf(title, escapedTerm, true);

    return getHighlighted(title, indices, escapedTerm.length, Highlighter);
  }, [term, title]);

  return (
    <div data-testid="SuggestTermInfo">
      <UIText2 data-testid="BuildingSuggestion">{highlighted}</UIText2>
      {description && <UIText2 color="gray60_100">{description}</UIText2>}
    </div>
  );
};

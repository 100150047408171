import * as React from 'react';
import { Button } from '@cian/ui-kit';
import { TButtonSize } from '@cian/ui-kit/button/types';

import * as styles from './styles.css';

interface IMessageButtonProps {
  onClick: () => void;
  buttonSize: TButtonSize;
  children: string;
}

export const MessageButton: React.FC<IMessageButtonProps> = ({ onClick, buttonSize, children }) => {
  return (
    <div className={styles['chat-button']}>
      <Button onClick={onClick} size={buttonSize} theme="fill_secondary" type="button">
        {children}
      </Button>
    </div>
  );
};

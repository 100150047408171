import { EBuilderChatActionTypes, TBuilderChatActions } from '../../actions/builderChat';
import { ENBConsutantActions, TConsultantChatActions } from '../../actions/consultantChat';
import { IBuilderChatState } from '../../types/builderChatState';

const initialState: IBuilderChatState = {
  isOpened: false,
  gaLabel: '',
  defaultMessage: undefined,
  builderRealtyUserId: null,
};

export function builderChatReducer(
  state: IBuilderChatState = initialState,
  action: TBuilderChatActions | TConsultantChatActions,
): IBuilderChatState {
  switch (action.type) {
    case EBuilderChatActionTypes.OpenChat:
      return {
        ...state,
        isOpened: true,
        gaLabel: action.payload.gaLabel,
        defaultMessage: action.payload.defaultMessage,
        builderRealtyUserId: action.payload.builderRealtyUserId,
      };

    case EBuilderChatActionTypes.CloseChat:
      return {
        ...state,
        isOpened: false,
      };

    // Закрываем чат с застройщиком, если открывается чат с консультантом.
    case ENBConsutantActions.ToggleChat:
      if (action.payload) {
        return {
          ...state,
          isOpened: false,
        };
      }

      return state;

    default:
      return state;
  }
}

import * as React from 'react';
import { OnboardingTooltip } from '@cian/ui-kit';
import { SalesStartLabel } from '../../components/SalesStartLabel';

export interface ISalesStartLabelOnboarding {
  description: string;
  tooltipContent: ISalesStartTooltip;
  openTooltip: boolean;
  onClose(): void;
}

export interface ISalesStartTooltip {
  header: string;
  content: string;
}

export function SalesStartLabelOnboarding(props: ISalesStartLabelOnboarding) {
  const { description, tooltipContent, openTooltip, onClose } = props;

  return (
    <OnboardingTooltip
      {...tooltipContent}
      placement={'right'}
      open={openTooltip}
      onClose={onClose}
      theme="blue"
      portal={false}
    >
      <span>
        <SalesStartLabel description={description} />
      </span>
    </OnboardingTooltip>
  );
}

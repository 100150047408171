import { getUserData } from '../../api';
import { IGetUserDataByRequestResponse } from '../../repositories/monolith-python/v1/get-user-data-by-request';
import { IThunkActionCreator } from '../../types/thunk';
import { TUser } from '../../types/user';
import { EUserActions } from './user';

export function updateUserDataAction(): IThunkActionCreator<Promise<TUser | undefined>> {
  return async (dispatch, getState, context) => {
    const state = getState();
    const { httpApi, logger } = context;
    const { subdomain } = state.location;
    // TODO: Разобраться с cookie в getUserData
    const userData = await getUserData('', logger, httpApi, subdomain);
    const user = (userData as IGetUserDataByRequestResponse).data.user as TUser;
    dispatch({ type: EUserActions.fetchUserDataSuccess, user });

    return user;
  };
}

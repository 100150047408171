import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/actionGenerator';

export enum EBuilderChatActionTypes {
  OpenChat = 'builderChat/open',
  CloseChat = 'builderChat/close',
}

export interface IOpenBuilderChatPayload {
  builderRealtyUserId?: number | null;
  gaLabel: string;
  defaultMessage?: string;
}

export const openBuilderChat = actionGenerator<EBuilderChatActionTypes.OpenChat, IOpenBuilderChatPayload>(
  EBuilderChatActionTypes.OpenChat,
);

export const closeBuilderChat = actionGenerator<EBuilderChatActionTypes.CloseChat, void>(
  EBuilderChatActionTypes.CloseChat,
);

export type TBuilderChatActions =
  | ITypedReduxAction<EBuilderChatActionTypes.OpenChat, IOpenBuilderChatPayload>
  | ITypedReduxAction<EBuilderChatActionTypes.CloseChat, void>;

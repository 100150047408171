import { IEvent, IConsumer, createConsumer, createSubscriber } from '@cian/events-log';

import { IRequestAuthenticationParameters } from './types';
import type { IThunkActionCreator } from '../../types/thunk';
import { getUser } from '../../selectors/user';
import { updateUserDataAction } from '../user';

export const TOPIC_USER = 'user';

type TConsumerSingleton = (() => IConsumer) & { _instance?: IConsumer };

const getUserConsumer: TConsumerSingleton = (): IConsumer => {
  if (!getUserConsumer._instance) {
    getUserConsumer._instance = createConsumer({ topic: TOPIC_USER });
  }

  return getUserConsumer._instance;
};

export function subscribeOnAuthentification(parameters?: IRequestAuthenticationParameters): IThunkActionCreator {
  return (dispatch, getState) => {
    if (typeof window !== 'undefined') {
      const consumer = getUserConsumer();

      const userSubscriber = createSubscriber(async (event: IEvent<unknown>) => {
        if (event.type === 'authenticated') {
          const currentUser = getUser(getState());

          if (currentUser?.isAuthenticated) {
            consumer.unsubscribe(userSubscriber);

            return;
          }

          await Promise.all([dispatch(updateUserDataAction())]);

          if (window.__reloadHeader__) {
            window.__reloadHeader__();
          }

          if (parameters && parameters.onSuccess) {
            dispatch(parameters.onSuccess());
          }

          consumer.unsubscribe(userSubscriber);

          return;
        }

        if (event.type === 'authentication_modal_closed') {
          if (parameters && parameters.onCloseNoAuth) {
            dispatch(parameters.onCloseNoAuth());
          }

          consumer.unsubscribe(userSubscriber);
        }
      });

      consumer.subscribe(userSubscriber);
    }
  };
}

import { useMemo } from 'react';
import { IBuilder } from '../../../types/newbuilding';

type TBuilderChatParams = {
  isChatEnabled: boolean;
  defaultMessage?: string;
  builderRealtyUserId?: number | null;
};

export const useBuilderChatParams = (builders: IBuilder[]): TBuilderChatParams => {
  const params = useMemo(() => {
    return builders.reduce<TBuilderChatParams>(
      (acc, builder) => {
        if (builder.chat) {
          acc.isChatEnabled = builder.chat.isEnabled;
          acc.defaultMessage = builder.chat.defaultMessage;
          acc.builderRealtyUserId = builder.realtyUserId;
        }

        return acc;
      },
      {
        isChatEnabled: false,
      },
    );
  }, [builders]);

  return params;
};
